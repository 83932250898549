// Routing
import { Outlet } from "react-router-dom";
// Styles
import Styles from "./styles.module.scss";
// Components
import Header from "../Header";
import Footer from "../Footer";
import LanguageBlock from "../LanguageBlock";

const Main = ({
  footer,
  header,
  consultButton,
  navigateTo,
  hideGetStarted,
  hideSchoolNav,
  hideSignIn,
  languageBlockDisplayMediaQuery,
}) => {
  return (
    <div className={Styles.container}>
      <Header
        navigateTo={navigateTo}
        header={header}
        consultButton={consultButton}
        hideSchoolNav={hideSchoolNav}
        hideSignIn={hideSignIn}
        languageBlockDisplayMediaQuery={languageBlockDisplayMediaQuery}
      />
      <LanguageBlock />
      <main className={Styles.main_content}>
        <Outlet />
      </main>
      <div style={{ width: "100%", background: "#ffffff" }}>
        {footer && <Footer hideGetStarted={hideGetStarted} />}
      </div>
    </div>
  );
};

export default Main;
