import { Box, Flex, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { useLocal } from "hooks/useLocal";
import { useMemo } from "react";

// Add this import for testimonial images

export const Testimonials = () => {
  const { t, isArabic } = useLocal();

  const testimonials = useMemo(
    () => t("testimonials.testimonials", { returnObjects: true }),
    [isArabic]
  ) as Array<{ name: string; text: string }>;
  const width = useMemo(() => testimonials.length * 430, [testimonials.length]);
  return (
    <>
      {/* Add Testimonials Section */}
      <LandingSectionContainer
        flexDirection={["column"]}
        justifyContent={"center"}
        // mb={["50px", "50px", "100px"]}
        alignItems="center"
        maxWidth="auto"
        height={["auto"]}
        width={["100%"]}
        mt={[30, 30, 30, 0]}
        mx={2}
        padding={0}
      >
        <Text
          color="typePurpure.200"
          textAlign="center"
          mb={[16, 16, 16, 30]}
          variant="heading1"
        >
          {t("testimonials.title")}
        </Text>
        <Box
          display={["block", "block", "block", "block", "block", "flex"]}
          justifyContent={["center"]}
          overflowX={["auto", "auto", "auto", "auto", "auto", "hidden"]}
          width="100%"
        >
          <Flex
            justifyContent="center"
            alignItems={["flex-start", "flex-start", "flex-start", "center"]}
            gap={[20, 20, 20, 20, 20, 20, 50]}
            width={[width]}
            px={[4, 4, 4, 0]}
          >
            {testimonials.map((testimonial, index) => (
              <Flex
                bg="white"
                boxShadow="lg"
                key={index + testimonial.name}
                flexDirection="column"
                borderRadius={1}
                width={[400]}
                mb={[20]}
                p={3}
                // scale={[1, 1, 1, 1, 1, 0.9, 1]}
                // style={{ scale: 0.5 }}
              >
                <Text variant="body2" mb={[2]}>
                  "{testimonial.text}"
                </Text>
                <Text textAlign={["right"]} variant="body3" fontWeight="bold">
                  - {testimonial.name}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Box>
      </LandingSectionContainer>
    </>
  );
};
