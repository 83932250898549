import { useState } from "react";
import axios from "axios";
import { apiPath } from "../../../constants";
import { HTTPMethods } from "types/type";

export const useApiCall = (onSuccess?: () => void) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>(null);

  const callApi = async (
    endpoint: string,
    method: HTTPMethods = "GET",
    body?: any
  ) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios({
        method,
        url: `${apiPath}${endpoint}`,
        data: body,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setData(response.data);
      setSuccess(true);
      onSuccess?.();
      return response.data;
    } catch (err) {
      console.log({ err });
      setError(
        axios.isAxiosError(err)
          ? err.response?.data?.message
          : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  return { success, loading, error, data, callApi };
};
