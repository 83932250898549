import { Text } from "components/Core";
import { ErrorMessage } from "formik";

type Props<T> = {
  name: T;
};
export const FormikError = <T,>({ name }: Props<T>) => {
  return (
    <ErrorMessage name={name as string}>
      {(message) => {
        return (
          <Text variant="error" color="error">
            {message}
          </Text>
        );
      }}
    </ErrorMessage>
  );
};
