import { CenterRow, Flex, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { areasWeProvideSupport } from "constants/index";
import { useLocal } from "hooks/useLocal";
import { theme } from "theme/theme";

export const AreasWeProvideSupport = () => {
  const { direction, t } = useLocal();
  return (
    <LandingSectionContainer
      height={"auto"}
      padding={[20, 20, 60]}
      background={theme.colors.gradient.purple}
      borderRadius={[32]}
      flexDirection={["column"]}
      color="white"
      width={["100%", "94%", "99%", "99%", "99%", "95%", "90%"]}
    >
      <Text
        textAlign="center"
        maxWidth="100%"
        color="white"
        direction={direction}
        mb={35}
        variant="heading1"
      >
        {t("areasWeProvideSupport")}
      </Text>
      <Flex
        width="100%"
        gap={[16, 16, 16, 16, "10px", 12, 20]}
        justifyContent={[
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "flex-start",
          "space-around",
        ]}
        flexWrap="wrap"
        flexDirection={["column", "column", "column", "row", "row"]}
        direction={direction}
      >
        {Array.from(
          { length: Math.ceil(areasWeProvideSupport.length / 3) },
          (_, columnIndex) => (
            <Flex key={columnIndex} flexDirection="column" gap={20}>
              {areasWeProvideSupport
                .slice(columnIndex * 3, columnIndex * 3 + 3)
                .map((area, index) => (
                  <CenterRow
                    key={index}
                    borderRadius={"36px"}
                    p={10}
                    border={1}
                    justifyContent="center"
                    width="max-content"
                  >
                    <Text
                      width="max-content"
                      textAlign="center"
                      variant="body5"
                    >
                      {t(`supportAreas.${area}`)}
                    </Text>
                  </CenterRow>
                ))}
            </Flex>
          )
        )}
      </Flex>
    </LandingSectionContainer>
  );
};
