import { Button, CenterRow, Flex, Text } from "components/Core";
import { useState } from "react";
import { appNumuwGetStartedUrl, routes } from "constants/index";
import {
  NumberedAccordion,
  NumberedAccordionDetails,
  NumberedAccordionSummary,
} from "components/NumberedAccordions/NumberedAccordions";
import { Link } from "react-router-dom";

type Props = {
  steps: { title: string; description: string }[];
  startNow: string | null;
  hideNumbers?: boolean;
  hideCta?: boolean;
};

export function HowItWorkSteps({
  steps,
  startNow,
  hideNumbers,
  hideCta,
}: Props) {
  const [expanded, setExpanded] = useState<string | false>();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Flex flexDirection={["column"]} width={"100%"}>
      {steps?.map((step, index) => (
        <NumberedAccordion
          key={step?.title}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
        >
          <NumberedAccordionSummary
            aria-controls="panel1d-content"
            id={`panel${index}d-header`}
          >
            <CenterRow gap="7px">
              {!hideNumbers && <Text variant="numberHolder">{index + 1}</Text>}
              <Text variant="body1">{step?.title}</Text>
            </CenterRow>
          </NumberedAccordionSummary>
          <NumberedAccordionDetails>
            <Text variant="body3">{step?.description}</Text>
          </NumberedAccordionDetails>
        </NumberedAccordion>
      ))}
      {!hideCta && (
        <Flex alignSelf="flex-end">
          <Link to={routes.talkToAdvisor}>
            <Button mt={[3]} variant="smallCTA" width="max-content">
              {startNow}
            </Button>
          </Link>
        </Flex>
      )}
    </Flex>
  );
}
