//Styles
import { Box, Button, Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
// Core
import { useSearchParams } from 'react-router-dom';

const Growth = ({
  title,
  description,
  isArabic,
  direction,
  heroImage,
  buttonLabel,
  headingVariant,
  subDescription,
  imageWidth,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenConsultation = () => {
    searchParams.set('modal', 'hint')
    setSearchParams(searchParams)
  };

  return (
    <LandingSectionContainer gap={[16]}>
      <Flex
        flexDirection={["column"]}
        width={["100%", "100%", "100%", "48%"]}
        direction={direction}
        mr={[0, 0, 0, 40]}
        gap={[16, 16, 16, 36]}
      >
        <Text
          textAlign={[isArabic ? "right" : "left"]}
          variant={headingVariant ?? "heading1"}
        >
          {title}
        </Text>
        <Text variant="charcoalBody">{description}</Text>
        {/* <Text variant="charcoalBody">{subDescription}</Text> */}
        <Button
          display={["none", "none", "none", "block"]}
          variant="getStarted"
          // my={[36, 36, 36, 15, 15, , 36]}
          width={["100%", "100%", "100%", "max-content"]}
          button
          onClick={handleOpenConsultation}
        >
          {buttonLabel}
        </Button>
      </Flex>
      <Box
        borderRadius={15}
        width={["100%", "100%", "100%", "45%"]}
        bg="black.100"
      >
        <Image width={imageWidth ?? "100%"} src={heroImage} />
        <Button
          mt={16}
          display={["block", "block", "block", "none"]}
          variant="getStarted"
          // my={[36, 36, 36, 15, 15, , 36]}
          width={["100%", "100%", "100%", "max-content"]}
          button
          onClick={handleOpenConsultation}
        >
          {buttonLabel}
        </Button>
      </Box>
    </LandingSectionContainer>
  );
};

export default Growth;
