//Core
import React, { useEffect } from "react";
//Styles
import Styles from "./styles,.module.scss";
//Router
import { useSearchParams, useLocation } from "react-router-dom";
//Translation
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Box } from "components/Core";

const LanguageBlock = () => {
  const { t, i18n } = useTranslation(["common"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  useEffect(() => {
    if (searchParams.get("lang")) {
      i18next.changeLanguage(searchParams.get("lang"));
    } else if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("ar");
    }
    searchParams.set('lang', localStorage.getItem("i18nextLng"))
    setSearchParams(searchParams);
  }, []);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setSearchParams({ lang: lang });
  };
  const currentLang = localStorage.getItem("i18nextLng");

  return (
    <Box display={["block", "block", "block", "block", "none"]}>
      {pathname === "/" && (
        <div className={Styles.container}>
          <button
            className={
              currentLang === "en"
                ? Styles.lang_select_active
                : Styles.lang_select
            }
            onClick={() => handleLanguageChange("en")}
          >
            English
          </button>
          <span className={Styles.divider}>|</span>
          <button
            className={
              currentLang === "ar"
                ? Styles.lang_select_active
                : Styles.lang_select
            }
            onClick={() => handleLanguageChange("ar")}
          >
            العربية
          </button>
        </div>
      )}
    </Box>
  );
};

export default LanguageBlock;
