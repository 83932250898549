export const dataForParnetHintModalEnglish = [
  { id: 1, label: 'Complete Our Questionnaire', description: `Provide detailed information about your child's needs.` },
  { id: 2, label: 'Book Your Free Consultation', description: `Schedule a consultation with one of our advisors.` },
  { id: 3, label: 'Meet with an Advisor', description: `Have a detailed discussion with our advisor to assess your requirements and explore suitable options.` },
  {
    id: 4,
    label: 'Receive Specialist Matches',
    description: `Get matched with a specialist based on the screening and consultation, and arrange a discovery call to learn more about them.`,
  },
  { id: 5, label: 'Connect With Your Matched Specialist', description: `Note: If they are not the right fit, you can receive additional matches.` },
]

export const dataForParnetHintModalArabic = [
  { id: 1, label: 'استكمال الاستبيان', description: 'قدم معلومات تشمل الاحتياجات الخاصة لطفلك' },
  { id: 2, label: 'حجز موعد للاستشارة المجانية', description: 'حدد موعد للمقابلة المستشار للحصول على الاستشارة' },
  { id: 3, label: 'مقابلة المستشار', description: 'ناقش احتياجتك بالتفصيل للوصول إلى الخيارات المناسبة' },
  { id: 4, label: 'الحصول على خيارات المختصين', description: 'سيتم اختيار مختصين بناء على الاستبيان و التواصل معهم لتزويدك بالمعلومات' },
  { id: 5, label: 'اختيار المختص المناسب لبدء الرحلة العلاجية', description: 'ملاحظة: في حال عدم اختيار المختص المناسب- يمكنك الحصول على خيارات أخرى' },
]