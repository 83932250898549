import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { ENVIRONMENT, GOOGLE_TAG_MANAGER_ID } from "../../constants";

const currentDomain = window.location.hostname;
const fireOn = currentDomain === "numuw.com" && ENVIRONMENT === "production";

export const GoogleTagManager = () => {
  useEffect(() => {
    if (fireOn) {
      TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
    }
  }, []);

  return null;
};

export const sendConversion = (eventName) => {
  if (window.dataLayer && fireOn) {
    window.dataLayer.push({
      event: eventName,
      conversion: true,
    });
  }
};
