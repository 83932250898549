//Styles
import Styles from "./styles.module.scss";

//Images

import company_1 from "../../assets/images/company_1.png";
import company_2 from "../../assets/images/company_2.png";
import company_3 from "../../assets/images/company_3.png";
import company_4 from "../../assets/images/company_4.png";
import company_5 from "../../assets/images/company_5.png";
import { Text } from "components/Core";
import { useLocal } from "hooks/useLocal";

const companies = [
  {
    id: 1,
    img: company_1,
    class: Styles.company_1,
  },
  {
    id: 2,
    img: company_2,
    class: Styles.company_2,
  },
  {
    id: 3,
    img: company_3,
    class: Styles.company_3,
  },
  {
    id: 4,
    img: company_4,
    class: Styles.company_4,
  },
  {
    id: 5,
    img: company_5,
    class: Styles.company_5,
  },
];

const CompanyCooperation = () => {
  const { t } = useLocal();
  return (
    <section className={Styles.wrapper}>
      <Text variant="heading2" textAlign={["left", "left", "center"]} mb={[20]}>
        {t("ourExpertsAreLicensedBy")}
      </Text>
      <div className={Styles.companies_wrapper}>
        <div className={Styles.companies_wrapper_block}>
          <div className={Styles.wrapper_img_first}>
            <img src={company_1} alt="logo" className={Styles.company_1} />
            <img src={company_2} alt="logo" className={Styles.company_2} />
            <img src={company_3} alt="logo" className={Styles.company_3} />
          </div>
          <div className={Styles.wrapper_img_second}>
            <img src={company_4} alt="logo" className={Styles.company_4} />
            <img src={company_5} alt="logo" className={Styles.company_5} />
          </div>
        </div>
        <div
          className={`${Styles.companies_wrapper} ${Styles.companies_wrapper_block_desktop}`}
        >
          {companies.map((company) => {
            return (
              <img
                key={company.id}
                src={company.img}
                alt="logo"
                className={company.class}
              />
              // <div key={index} className={`${Styles.company} ${company}`}></div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default CompanyCooperation;
