//Translation
import { useTranslation } from 'react-i18next'

//Core
import React, { useEffect } from 'react'
import { useSearchParams  } from 'react-router-dom'

//Styles
import Styles from './styles.module.scss'

const ThankYou = () => {

	const { t, i18n } = useTranslation(['common'])
    const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        setSearchParams({ lang: localStorage.getItem('i18nextLng') })
    }, [])

	return (
		<section className={Styles.wrapper}>
			<div className={Styles.content_wrap}>
                <div className={Styles.text_wrap}>
					<h1 dir={textDirection}>{t('thank_you.request')}</h1>
                	<h2 dir={textDirection}>{t('thank_you.soon')}</h2>
                </div>
				<div className={`${Styles.element} ${Styles.curve_1}`} />
				<div className={`${Styles.element} ${Styles.curve_2}`} />
				<div className={`${Styles.element} ${Styles.curve_3}`} />
				<div className={`${Styles.element} ${Styles.curve_4}`} />
				<div className={`${Styles.element} ${Styles.curve_5}`} />
			</div>
		</section>
	)
}

export default ThankYou
