import styled from "styled-components";
import { variant } from "styled-system";
import { commonComposes } from "../common/commonComposes";
import { ButtonProps } from "./types";
import { theme } from "theme/theme";

export const Button = styled("button")<ButtonProps>(
  (props) => ({
    cursor: "pointer",
    "&:hover": props._hover ?? {
      opacity: 0.8,
      transition: "all 0.2s ease-in-out",
    },
  }),
  variant({
    variants: {
      primary: {
        width: "max-content",
        fontWeight: 500,
        fontSize: "16px",
        fontFamily: "Poppins_Medium_500, sans-serif",
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
        backgroundColor: theme.colors.typePurpure[200],
        borderRadius: "15px",
        padding: "10px 20px",
        border: "none",
      },
      ghost: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#8450A0",
        backgroundColor: "transparent",
        borderRadius: "10px",
        border: "none",
        cursor: "pointer",
        fontSize: "14px",
        // padding: "11.5px 20px",
        "&:hover": {
          background: theme.colors.black[200],
        },
      },
      secondary: {
        width: "max-content",
        fontWeight: 500,
        fontSize: "16px",
        fontFamily: "Poppins_Medium_500, sans-serif",
        color: theme.colors.typePurpure[200],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
        backgroundColor: "white",
        borderRadius: "50px",
        padding: "10px 20px",
        border: `1px solid ${theme.colors.typePurpure[200]}`,
      },
      sm_secondary: {
        width: "max-content",
        fontWeight: 500,
        fontSize: "16px",
        fontFamily: "Poppins_Medium_500, sans-serif",
        color: theme.colors.black[900],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px 20px",
        border: `2px solid ${theme.colors.secondary}`,
      },
      getStarted: {
        backgroundColor: theme.colors.secondary,
        border: "none",
        borderRadius: "15px",
        color: theme.colors.black[900],
        cursor: "pointer",
        fontSize: "23px",
        fontWeight: 600,
        padding: "15px 25px",
        // width: "auto",
      },
      smallCTA: {
        backgroundColor: theme.colors.secondary,
        fontSize: theme.fontSizes[1],
        color: theme.colors.black[900],
        fontWeight: 600,
        border: "none",
        borderRadius: "10px",
        padding: "11.5px 20px",
        cursor: "pointer",
        maxWidth: "100%",
        width: "max-content",
      },
    },
  }),
  commonComposes()
);

Button.defaultProps = {
  variant: "primary",
};
