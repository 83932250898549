//Styles
import Styles from './styles.module.scss'
import TermsOfUseLink from '../../documents/terms_of_use.pdf'

const TermsOfUse = () => {
	return (
		<section className={Styles.wrapper}>
			<h3 style={{marginBottom: '20px'}}>Terms of Use</h3>
			<iframe title='terms' src={`${TermsOfUseLink}#toolbar=0&navpanes=0`} height={'100%'} width={'100%'}/>
		</section>
	)
}

export default TermsOfUse
