import styled from "styled-components";
import { commonComposes } from "../common/commonComposes";
import { TextAreaProps } from "./types";

export const TextArea = styled("textarea")<TextAreaProps>(
  (props) => ({
    width: "100%",
    // height: "56px",
    fontFamily: "Poppins, sans-serif",
    boxSizing: "border-box",
    border: "1px solid #d9d9d9",
    borderRadius: "5px",
    padding: "11.5px 10px",
    outline: "none",
  }),
  commonComposes()
);
