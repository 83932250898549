import { Link } from "react-router-dom";
//Styles
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from "react-i18next";
import { Button } from "components/Core";
// Core
import { useSearchParams } from 'react-router-dom';

const InviteToFillForm = (props) => {
  const { t, i18n } = useTranslation(["common"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  const [searchParams, setSearchParams] = useSearchParams();

  const handlePreOpenConsultation = () => {
    searchParams.set('modal', 'hint')
    setSearchParams(searchParams)
  };

  return (
    <section className={Styles.wrapper}>
      <div className={Styles.card} dir={textDirection}>
        <h1
          className={`${Styles.title} ${
            !props.getConsultation ? Styles.change_width : null
          }`}
        >
          {props.getConsultation
            ? `${t("joinParent.fillFormParentTitle")}`
            : `${t("joinTherapist.fillFormTherapistTitle")}`}
        </h1>
        {props.getConsultation ? (
          <Button
            variant="smallCTA"
            className={Styles.consult_btn}
            onClick={handlePreOpenConsultation}
          >
            {t("buttons.bookFreeConsultation")}
          </Button>
        ) : (
          <Link to="/join-us" className={Styles.consult_btn}>
            {t("buttons.becomeTherapist")}
          </Link>
        )}
      </div>
    </section>
  );
};

export default InviteToFillForm;
