//Styles
import Styles from './styles.module.scss'
import privacyPolicyLink from '../../documents/privacy_policy.pdf'

const TermsOfUse = () => {
	return (
		<section className={Styles.wrapper}>
			<h3 style={{marginBottom: '20px'}}>Privacy Policy</h3>
			<iframe title='terms' src={`${privacyPolicyLink}#toolbar=0&navpanes=0`} height={'100%'} width={'100%'}/>
	  </section>
	)
}

export default TermsOfUse
