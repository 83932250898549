import { Link } from "react-router-dom";
import Styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import { Button, Text } from "components/Core";
import { routes } from "constants/index";

export const SchoolCTA = () => {
  const { t, i18n } = useTranslation(["school"]);
  const textDirection = i18n.language === "en" ? "ltr" : "rtl";

  return (
    <section className={Styles.wrapper}>
      <div className={Styles.card} dir={textDirection}>
        <Text mb={[16]} color="white" variant="heading3">{`${t(
          "schoolCTA.title"
        )}`}</Text>
        <Link to={routes.talkToAdvisor}>
          <Button variant="smallCTA" className={Styles.consult_btn}>
            {t("buttons.meetAdvisor")}
          </Button>
        </Link>
      </div>
    </section>
  );
};
