import type { TFunction } from "i18next";
import * as yup from "yup";

export const talkToAdvisorValidation = (
  t: TFunction<["school"], undefined>
) => {
  const requiredMessage = t(
    "talkToAdvisor.formErrorMessages.required"
  ) as string;
  const invalidEmail = t(
    "talkToAdvisor.formErrorMessages.invalidEmail"
  ) as string;

  return yup.object().shape({
    full_name: yup.string().required(requiredMessage),
    work_email: yup.string().email(invalidEmail).required(requiredMessage),
    job_title: yup.string().required(requiredMessage),
    school_name: yup.string().required(requiredMessage),
  });
};

export type TalkToAdvisorValues = {
  full_name: string;
  work_email: string;
  job_title: string;
  school_name: string;
  phone_number: string;
  additional_info: string;
};

export type Keys = keyof TalkToAdvisorValues;

export const talkToAdvisorInitialValues: TalkToAdvisorValues = {
  full_name: "",
  work_email: "",
  job_title: "",
  school_name: "",
  phone_number: "",
  additional_info: "",
};
