// Components
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ClientAcquisition, InviteToFillForm, TherapistSolution } from '../components'

const JoinOurNetworkPage = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	useEffect(() => {
		setSearchParams({ lang: localStorage.getItem('i18nextLng') })
	}, [])

	return (
		<>
			<TherapistSolution />
            <ClientAcquisition />
			<InviteToFillForm getConsultation={false} />
		</>
	)
}

export default JoinOurNetworkPage
