import styled from "styled-components";
import { commonComposes } from "../common/commonComposes";
import { InputProps } from "./types";
import { variant } from "styled-system";
import { theme } from "theme/theme";

export const Input = styled("input")<InputProps>(
  (props) => ({
    // maxWidth: "418px",
    boxSizing: "border-box",
    border: "1px solid #d9d9d9",
    borderRadius: "5px",
    padding: "11.5px 10px",
    outline: "none",
    fontSize: "16px",
  }),
  variant({
    variants: {
      checkbox: {
        width: 20,
        "-webkit-appearance": "none",
        backgroundColor: "transparent",
        border: "1px solid #d9d9d9",
        borderRadius: "3px",
        padding: "10px",
        display: "inline-block",
        position: "relative",
        verticalAlign: "middle",
        cursor: "pointer",
        marginRight: "5px",
        "&:checked::before": {
          content: '""',
          display: "block",
          position: "absolute",
          top: "2px",
          left: "6px",
          width: "5px",
          height: "11px",
          border: "solid var(--accent-color)",
          borderRadius: "1px",
          borderWidth: "0 2px 2px 0",
          transform: "rotate(35deg)",
        },
        "&:checked": {
          borderColor: theme.colors.typePurpure[200],
        },
      },
    },
  }),
  commonComposes()
);
