import type { ListType } from "types/type";
import type { DirectionType } from "components/Core/common/types";
import { Flex, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { HowItWorkSteps } from "./HowItWorkSteps";

type Props = {
  isArabic: boolean;
  direction: DirectionType;
  steps: ListType;
  title: string | null;
  startNow: string | null;
  hideNumbers?: boolean;
  hideCta?: boolean;
};
export const PartnershipWithNumuw = ({
  direction,
  isArabic,
  steps,
  startNow,
  title,
  hideNumbers,
  hideCta,
}: Props) => {
  return (
    <LandingSectionContainer
      justifyContent={["space-between"]}
      height={"auto"}
      gap={[40]}
    >
      {/* <Image src={opportunity} width={["100%", "100%", "80%", "25%"]} /> */}
      <Flex
        mr={isArabic ? [0] : 0}
        direction={direction}
        flexDirection={["column"]}
        flexGrow={1}
      >
        <Flex flexDirection="column" width={["100%"]}>
          <Text mb={[10]} variant="heading1">
            {title}
          </Text>
          <HowItWorkSteps
            hideNumbers={hideNumbers}
            startNow={startNow}
            steps={steps}
            hideCta={hideCta}
          />
        </Flex>
      </Flex>
    </LandingSectionContainer>
  );
};
