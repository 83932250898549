import { Box, Button } from "components/Core";
import type { ButtonProps } from "components/Core/Button/types";
import { externalRoutes } from "constants/index";

interface Props extends ButtonProps {
  label: string;
}
export const LoginButton = ({ label, ...rest }: Props) => {
  return (
    <Box width={rest.width}>
      <a href={externalRoutes.loginApp} target="_blank">
        <Button variant="sm_secondary" {...rest}>
          {label}
        </Button>
      </a>
    </Box>
  );
};
