import { ThankYou } from "../components"

const ThankYouPage = () => {
	return (
		<>
			<ThankYou />
		</>
	)
}

export default ThankYouPage
