import {
  dataProtection,
  nhraLogo,
  pdplGreen,
  PDPLIcon,
  pdplViolet,
} from "assets/images";
import { Box, CenterRow, Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { useSchoolLocal } from "../useSchoolLocal";

export const Trusted = () => {
  const { direction, isArabic, t } = useSchoolLocal();

  return (
    <LandingSectionContainer
      justifyContent={["center"]}
      bg="gray.200"
      padding={[20, 20, 20, 30, 60]}
      py={[40, 40, 40, 60]}
      height={"auto"}
      gap={[40]}
      alignItems={["center"]}
      borderRadius={[0, 0, 10]}
      position="relative"
      backgroundImage={`url(${dataProtection})`}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      width={["95%", "94%", "98%", "98%", "90%", "85%"]}
    >
      <Flex
        mr={isArabic ? [0] : 0}
        direction={direction}
        flexDirection={["column"]}
        width={["100%", "100%", "100%", "52%"]}
      >
        <Flex
          //   alignItems={isArabic ? "flex-start" : "flex-end"}
          flexDirection="column"
          width={["100%"]}
          gap={[24]}
        >
          <Text variant="heading2" fontSize={"30px"}>
            {t("trusted.title")}
          </Text>
          <Text variant="body5">{t("trusted.subTitle")}</Text>
          <Text color="black.900" variant="body4">
            {t("trusted.subHeading")}
          </Text>
          <Text variant="body3">{t("trusted.description")}</Text>
        </Flex>
      </Flex>
      <CenterRow justifyContent={["center"]}>
        <Box width={[200, 200, "250px"]}>
          <Image src={nhraLogo} width={["100%"]} />
        </Box>
        <Box width={[85, 85, 85, 100]}>
          <Image src={pdplGreen} width={["100%"]} />
        </Box>
      </CenterRow>
    </LandingSectionContainer>
  );
};
