// Components
import {
  CompanyCooperation,
  Experts,
  InviteToFillForm,
  Growth,
} from "../components";

import { useMemo, useState } from "react";
import PreConsultationPopup from "../components/PreConsultationPopup";
import { AreasWeProvideSupport } from "components/NewDesignComponents/AreasWeProvideSupport/AreaWeProvideSupport";
import { WhyChooseNumuw } from "components/NewDesignComponents/WhyChooseNumuw/WhyChooseNumuw";
import { HowItWorks } from "components/NewDesignComponents/HowItWorks/HowItWorks";
import { Trusted } from "components/NewDesignComponents/Trusted/Trusted";
import { Testimonials } from "components/NewDesignComponents/Testimonials/Testimonials";
import { whyChooseNumuw, heroImage } from "assets/images";
import { useLocal } from "hooks/useLocal";
// Core
import { useSearchParams } from 'react-router-dom';

const MainPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();


  const handlePreCloseConsultation = () => {
    searchParams.delete('modal')
    setSearchParams(searchParams)
  };
  
  const { direction, isArabic, t } = useLocal();
  const reasons = useMemo(
    () => t("whyChooseNumuw.reasons", { returnObjects: true }),
    [isArabic]
  );
  // For How it works
  const howItWorksSteps = useMemo(
    () => t("howItWorks.steps", { returnObjects: true }),
    [isArabic]
  );

  return (
    <>
      <Growth
        heroImage={heroImage}
        description={t("hero.heroDescription")}
        direction={direction}
        isArabic={isArabic}
        title={t("hero.title")}
        buttonLabel={t("buttons.getStartedToday")}
        subDescription={t("hero.heroSubDescription")}
      />
      {/* <Mission /> */}
      <AreasWeProvideSupport />
      <WhyChooseNumuw
        direction={direction}
        image={whyChooseNumuw}
        isArabic={isArabic}
        reasons={reasons}
        subTitle={t("whyChooseNumuw.subTitle")}
        title={t("whyChooseNumuw.title")}
      />
      <HowItWorks
        direction={direction}
        isArabic={isArabic}
        startNow={t("buttons.startNow")}
        steps={howItWorksSteps}
        title={t("howItWorks.title")}
      />
      <Testimonials />
      <div style={{ width: "100%", background: "#F8F8F8" }}>
        <Experts />
        <CompanyCooperation />
      </div>
      <Trusted />
      <InviteToFillForm getConsultation={true} />
      <PreConsultationPopup
        onClose={handlePreCloseConsultation}
        open={searchParams.get('modal')}
      />
    </>
  );
};

export default MainPage;
