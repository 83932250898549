// Components
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TherapistForm } from '../components'

const TherapistFormPage = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	useEffect(() => {
		setSearchParams({ lang: localStorage.getItem('i18nextLng') })
	}, [])

	return (
		<>
			<TherapistForm />
		</>
	)
}

export default TherapistFormPage
