import { Box, Button, Flex, Text } from "components/Core";
import success from "assets/lotties/successGreen.json"; // Assuming you have a CheckIcon component
import { useSchoolLocal } from "../useSchoolLocal";
import Lottie from "react-lottie";
import { defaultOptions } from "assets/lotties/constants";
import { Link } from "react-router-dom";

export const FormSuccessPage = () => {
  const { t } = useSchoolLocal();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      my={[3, 46]}
    >
      <Box width={[230, 230, 300]} height={[230, 230, 300]}>
        <Lottie
          options={defaultOptions({ animationData: success })}
          height={"100%"}
          width={"100%"}
        />
      </Box>
      <Text mt={4} variant="heading2" textAlign="center">
        {t("talkToAdvisor.success.title")}
      </Text>
      <Text variant="body3" textAlign="center" maxWidth="600px">
        {t("talkToAdvisor.success.description")}
      </Text>
      <Link to="/school">
        <Button mt={4} variant="smallCTA">
          {t("buttons.backToHome")}
        </Button>
      </Link>
    </Flex>
  );
};
