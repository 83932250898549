// Components
import { TermsOfUse } from "../components";

const TermsOfUsePage = () => {
  return (
    <>
      <TermsOfUse />
    </>
  );
};

export default TermsOfUsePage;
