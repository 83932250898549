//Styles
import Styles from "./styles.module.scss";
//Traslation
import { useTranslation } from 'react-i18next'

const ClientAcquisition = () => {
  const { t, i18n } = useTranslation(['common'])
  const textDirection = i18n.language === 'en' ? 'ltr' : 'rtl'

  return (
    <section className={Styles.wrapper}>
      <div className={Styles.image} />

      <div dir={textDirection} className={Styles.content}>
        <h1 className={Styles.title}>{t("client.clientAcquisition")}</h1>
        <p className={Styles.description}>
          {t("client.clientAcquisitionDescr")}
        </p>
      </div>
    </section>
  );
};

export default ClientAcquisition;
