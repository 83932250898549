import type { ListType } from "types/type";
import type { DirectionType } from "components/Core/common/types";
import { howItWorks } from "assets/images";
import { Flex, Image, Text } from "components/Core";
import { LandingSectionContainer } from "components/shared/LandingSectionContainer";
import { HowItWorkSteps } from "./HowItWorkSteps";

type Props = {
  isArabic: boolean;
  direction: DirectionType;
  steps: ListType;
  title: string | null;
  startNow: string | null;
  hideNumbers?: boolean;
};
export const HowItWorks = ({
  direction,
  isArabic,
  steps,
  startNow,
  title,
  hideNumbers,
}: Props) => {
  // const { direction, isArabic, t } = useLocal();
  // const steps = useMemo(
  //   () => t("howItWorks.steps", { returnObjects: true }),
  //   [isArabic]
  // ) as Array<{ title: string; description: string }>;
  return (
    <LandingSectionContainer
      justifyContent={["space-between"]}
      flexDirection={[
        "column-reverse",
        "column-reverse",
        "column-reverse",
        "row",
      ]}
      // mb={["20px", "20px", "100px"]}
      height={"auto"}
      gap={[40]}
    >
      <Image src={howItWorks} width={["100%", "100%", "80%", "46%"]} />
      <Flex
        mr={isArabic ? [0] : 0}
        direction={direction}
        flexDirection={["column"]}
        flexGrow={1}
      >
        <Flex flexDirection="column" width={["100%"]}>
          <Text mb={[10]} variant="heading1">
            {title}
          </Text>
          <HowItWorkSteps
            hideNumbers={hideNumbers}
            startNow={startNow}
            steps={steps}
          />
        </Flex>
      </Flex>
    </LandingSectionContainer>
  );
};
